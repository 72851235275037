<template>
  <div>
    <b-card>
      <!-- search & new -->
      <b-row>
        <b-col cols="3">
          <b-form-select
            placeholder="应用行业"
            v-model="query_industry"
            :options="industryOptions"
          />
        </b-col>
        <b-col cols="3">
          <b-form-select
            placeholder="合规要求"
            v-model="query_compliance"
            :options="complianceOptions"
          />
        </b-col>
        <b-col cols="4">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="query_keyword"
              placeholder="法规内容、关键字"
              @keypress.enter="doSearch"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="cursor-pointer"
                @click="doSearch"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button variant="primary" @click="newItem"
            ><feather-icon icon="PlusIcon" class="mr-50" /><span
              class="align-middle"
              >新增</span
            ></b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <!-- list -->
    <b-row v-if="queryData" class="match-height">
      <b-col xl="3" md="3" v-for="(item, idx) in queryData.list" :key="idx" @click="$router.push(`/labrary/law/${item.id}`)">
        <b-card no-body class="lab-item" :footer="`发布日期 ${item.published}`" 
          footer-class="lab-footer">
          <b-card-header>
            <b-media vertical-align="top">
              <template #aside>
                <b-badge
                  v-for="(lawItem, lawIdx) in item.apply"
                  :key="`lay-${lawIdx}`"
                  :variant="variantLaws[lawItem]"
                  class="mr-1"
                >
                  <b-icon-shield-lock /> <span>{{ lawItem }}</span>
                </b-badge>
              </template>
            </b-media>
            <!-- <b-card-title>{{item.title.substr(0,8)}}...</b-card-title> -->
          </b-card-header>

          <b-card-body>
            <h2 class="lib-title">{{ item.title }}</h2>
            <div>{{ item.overview }}</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- pagination -->
    <b-row>
      <b-col cols="12" class="mt-1">
        <b-pagination
          v-if="queryData"
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!-- edit -->
    <!-- <EnterpriseEdit
      :item="currentItem"
      :visible="showEdit"
      @hidden="onEditHidden"
      @changed="doSearch"
    /> -->
  </div>
</template>


<script>
import {
  BMedia,
  BMediaAside,
  BIconShieldLock,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";
// import EnterpriseEdit from "./EnterpriseEdit.vue";

export default {
  name: "LibraryLaw",

  components: {
    BMedia,
    BMediaAside,
    BIconShieldLock,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    // EnterpriseEdit,
  },

  data() {
    return {
      queryData: null,

      // select options
      industryOptions: [
        { value: "", text: "应用行业" },
        "互联网",
        "商业",
        "贸易",
        "制造业",
        "农业",
        "医疗",
        "物流",
      ],
      complianceOptions: [
        { value: "", text: "合规要求" },
        "GDPR",
        "CCPA",
        "PIPL",
      ],

      //variant for laws
      variantLaws: {
        GDPR: "primary",
        CCPA: "secondary",
        PIPL: "success",
      },

      // query
      query_industry: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      listTableFields: [
        { key: "code", label: "编号" },
        { key: "name", label: "企业名称" },
        { key: "compliances", label: "合规要求" },
        { key: "contactName", label: "联系人" },
        { key: "contactPhone", label: "电话" },
        { key: "industry", label: "所在行业" },
        { key: "contactEmail", label: "邮箱" },
        { key: "attachments", label: "附件材料" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.list : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      this.$http
        .get("/api/lib-law", {
          params: {
            q: this.query_keyword,
            industry: this.query_industry,
            compliance: this.query_compliance,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.queryData = res.data;
        });
    },

    //
    editItem(data) {
      this.currentItem = JSON.parse(JSON.stringify(data.item));
      this.showEdit = true;
    },

    //
    newItem() {
      this.$router.push("/labrary/law/new");
    },

    //
    deleteItem(data) {
      let name = data.item.name;
      this.$bvModal
        .msgBoxConfirm(`是否确定删除企业“ ${name}”？`, {
          title: "删除确认",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          this.$http.delete(`/api/enterprise/${data.item.id}`).then((res) => {
            this.$bvToast.toast(`${name} 删除成功`, {
              title: `操作成功`,
              variant: "success",
              solid: true,
            });
            this.doSearch();
          });
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>

<style lang="scss" >

.lib-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
}
.lib-footer{
  font-size: 1rem !important;
  text-align: right !important;
}

.match-height>[class*=col] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    cursor: pointer;
}

.match-height>[class*=col]>.card {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}
</style>